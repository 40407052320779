export const environment = {
  production: true,
  firebase: {
    web: {
      apiKey: "AIzaSyDW2cnaT-q5Im4VYiOdUZyso1L136IhFJY",
      authDomain: "imigo-8a585.firebaseapp.com",
      projectId: "imigo-8a585",
      storageBucket: "imigo-8a585.appspot.com",
      messagingSenderId: "322947294981",
      appId: "1:322947294981:web:4bb15f86e8b2a626ad396c"
    },
    android: {
      apiKey: 'AIzaSyB74mYJuEm2e5y_z40nD61ZJIWHqbHubgY',
      authDomain: "imigo-8a585.firebaseapp.com",
      projectId: 'imigo-8a585',
      storageBucket: 'imigo-8a585.appspot.com',
      messagingSenderId: "322947294981",
      appId: '1:322947294981:android:ac73e2add1255baead396c',
    },
    ios: {
      apiKey: 'YOUR_IOS_API_KEY',
      authDomain: 'YOUR_IOS_AUTH_DOMAIN',
      projectId: 'YOUR_IOS_PROJECT_ID',
      storageBucket: 'YOUR_IOS_STORAGE_BUCKET',
      messagingSenderId: 'YOUR_IOS_MESSAGING_SENDER_ID',
      appId: 'YOUR_IOS_APP_ID',
    },
  },
};
